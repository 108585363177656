import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { apiClient } from "../services/http.helper";
import {
  paidUser,
  showTryModal,
  showUpgradeModal,
  trialAvailable,
} from "../storage";

const useAPI = (url, params, setPrivilege) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setPaid] = useAtom(paidUser);
  const [, setTrialAvailable] = useAtom(trialAvailable);
  const [cookies, setCookie] = useCookies(["premiumModalDate"]);
  const [, setOpenPremium] = useAtom(showUpgradeModal);
  const [, setOpenTryPremium] = useAtom(showTryModal);

  useEffect(() => {
    const updatePaidUser = async (paid, showTryPremium) => {
      if (!paid) {
        if (cookies.premiumModalDate) {
          if (cookies.premiumModalDate !== dayjs().date()) {
            if (showTryPremium) {
              setOpenTryPremium(showTryPremium);
            } else {
              setOpenPremium(true);
            }
            setCookie("premiumModalDate", String(dayjs().date()));
          }
        } else {
          if (showTryPremium) {
            setOpenTryPremium(showTryPremium);
          } else {
            setOpenPremium(true);
          }
          setCookie("premiumModalDate", String(dayjs().date()));
        }
      }
    };
    async function fetchData() {
      setLoading(true);
      try {
        const response = await apiClient.post(url, params);
        if (response.status) {
          let obj = response.data;
          obj.planName = response.planName;
          obj.end_date = response.end_date;
          if (obj.end_date) {
            const parsedDate = dayjs(obj.end_date, { format: "YYYY-MM-DD" });
            obj.end_date = parsedDate.format("DD-MM-YYYY");
          }
          setData(obj);
          if (response.privilege) {
            setPrivilege(response.privilege);
          }
          setPaid(response.paidUser);
          setTrialAvailable(response.showTryPremium);
          updatePaidUser(response.paidUser, response.showTryPremium);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [url]);

  return {
    data,
    loading,
    error,
  };
};

export default useAPI;
