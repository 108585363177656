import { Button, message, Switch, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { business_id } from '../../storage';
import { useAtom } from 'jotai';
import { apiClient } from '../../services/http.helper';
import { IconEdit } from '@tabler/icons-react';
import { errorNotification } from '../../utils/notification.helper';

const FIELD_TYPE = "PRODUCT";
export function ItemHeader(props) {
  const { onCustomFieldUpdate = () => { }, tabRefresh = false } = props;
  const [businessId] = useAtom(business_id);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  useEffect(() => {
    const getItemCustomFields = async () => {
      try {
        const obj = {
          business_id: businessId,
          custom_field_type: FIELD_TYPE,
        };
        const result = await apiClient.post("api/web/custom-field", obj);
        if (result.status) {
          setCustomFields(result.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        message.error("Something went wrong");
      }
    }
    getItemCustomFields();
  }, [businessId, FIELD_TYPE, tabRefresh])
  const columns = [
    {
      title: "Name",
      dataIndex: "field_name",
      key: "field_name",
      align: "left",
      render: (text, record) => <span className='font-size-14'>{text} <span className='text-gray-500'>({record?.default_key?.replace('_', ' ')})</span></span>
    },
    {
      title: "Show in PDF",
      dataIndex: "show_in_pdf",
      key: "show_in_pdf",
      render: (text, record) => record.show_pdf_toggle === true && <Switch size='small' checked={text} onChange={(e) => onChangeShowInPdfSwitch(record, e)} />

    },
    {
      title: "Active",
    },
    {
      title: "Actions",
      dataIndex: "active",
      key: "actions",
      render: (text, record) => <Button size='small' type='primary' onClick={() => onCustomFieldUpdate(record)} className='icon-btn' icon={<IconEdit size={16} />}></Button>
    }
  ];

  const onChangeShowInPdfSwitch = async (value, e) => {
    try {
      const data = { ...value, show_in_pdf: e };
      const obj = {
        business_id: businessId,
        ...data,
        custom_field_type: FIELD_TYPE,
      };
      const result = await apiClient.post("api/web/custom-field/store", obj);
      if (result.status) {
        const updatedFields = customFields.map((field) => {
          if (field.id === value.id) {
            return { ...field, show_in_pdf: e };
          }
          return field;
        });
        setCustomFields(updatedFields);
        message.success('Field Updated Successfully', 1);
      }
    } catch (error) {
      console.log(error);
      errorNotification(
        JSON.stringify(),
        "Custom field",
        JSON.stringify(error)
      );
    }
  };
  return (
    <Table
      columns={columns}
      dataSource={customFields}
      pagination={false}
      size='small'
      rowKey={"id"}
      scroll={{ x: "max-content" }}
    />
  )
}
