import { Worker } from "@react-pdf-viewer/core";
import { ConfigProvider, Spin, theme } from "antd";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorComponent from "./pages/ErrorComponent";
import { apiClient, AxiosInterceptor } from "./services/http.helper";
import { fullScreenLoader, themeMode, user_access_token } from "./storage";
import MainRoutes from "./utils/MainRoutes";
import NoInternetConnection from "./utils/NetworkStatusHook";
import ServerStatus from "./utils/ServerStatusHook";
import { PlanAccessProvider } from "./utils/planAccessContext";

import "./App.css";

function App() {
  const [loader] = useAtom(fullScreenLoader);
  const [theme_mode] = useAtom(themeMode);
  const [planAccess, setPlanAccess] = useState(null)
  const [loading, setLoading] = useState(false)
  const [user] = useAtom(user_access_token)
  const theme_config = {
    token: {
      colorPrimary: "#3062E1",
      colorBorder: "#e4e9f0",
      invoiceFooterBgColor: "#e4e3e3",
      colorInfo: "#2754ff",
      colorError: "#e11900",
      colorTextBase: "#000000",
      colorBorderSecondary: "#F4F4F9",
      colorBgSpotlight: "#1d1d1f",
      boxShadowSecondary: "0 0 9px rgba(0,0,0,0.1)",
      colorText: "#1d1d1f",
      colorTextSecondary: "#6e6e73",
      colorTextTertiary: "#86868b",
      colorTextQuaternary: "#AEAEB2",
      colorBgLayout: "#f5f5f7",
      fontSize: 14,
      fontFamily: "$base-font-family"
    },
    components: {
      Table: {
        cellFontSizeSM: "0.86 rem",
        cellPaddingInlineSM: 5.4,
        cellPaddingBlockSM: 9,
      },
    },
    algorithm: [theme_mode ? theme.darkAlgorithm : theme.defaultAlgorithm],
  };

  useEffect(() => {
    const getPermissions = async () => {
      try {
        setLoading(true)
        const result = await apiClient.post("/api/web/user/get-plan-permissions")

        if (result.status) {
          const features = result.features.map((item) => {
            if (item.plansFeature) {
              return item.plansFeature.short_desc
            }
          })
          const attributes = result.attributes.map((item) => {
            if (item.plansAttribute) {
              return item.plansAttribute.attribute_name
            }
          })

          setPlanAccess([...features, ...attributes])
          // setPlanAccess(["INVOICE", "User", "Business", "PARTY", "PRODUCTS", "REPORTS"])
          // setPlanAccess(["Invoices", "einvoice", "User", "Business", "ewaybill", "ecreditnote", "bulk product", "bulk upload", "pos", "Udhaar Khata", "GSTR1", "PARTY"])
        } else if (!result.status && result.trial) {
          setPlanAccess(["INVOICE", "USER", "BUSINESS", "PARTY", "PRODUCTS", "REPORTS", "ewaybill", "einvoice", "ecreditnote", "pos", "Udhaar Khata"])
        } else {
          setPlanAccess([])
        }

      } catch (error) {
        console.log(error);
        setPlanAccess([])
      } finally {
        setLoading(false)
      }
    }
    if (user) {
      getPermissions()
    } else {
      setLoading(false);
      setPlanAccess([])
    }
  }, [user])

  if (loading && user) {
    return <Spin spinning={true} fullscreen />
  }

  return (

    <Worker workerUrl="/js/pdf.worker.min.js">
      <ConfigProvider theme={theme_config}>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <CookiesProvider>
            <Spin spinning={loader} fullscreen />
            <NoInternetConnection>
              <ServerStatus>
                <PlanAccessProvider value={{ planAccess }}>
                <Router>
                  <MainRoutes />
                </Router>
                </PlanAccessProvider>
              </ServerStatus>
            </NoInternetConnection>
          </CookiesProvider>
        </ErrorBoundary>
        <AxiosInterceptor />
      </ConfigProvider>
    </Worker>
  );
}

export default App;
