import { Button, Col, Image, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import InvoicePermission from "../../../public/images/InvoicePermission.jpg";

const GSTR1Access = () => {
  return (
    <>
      <div style={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", margin: "40px" }}>
        <Typography.Title level={3}>
          Reports
        </Typography.Title>
        <Row
          gutter={[16, 16]}
          style={{
            maxWidth: "1200px",
            width: "100%",
            padding: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Image src={InvoicePermission} alt="invoice" preview={false} width="100%" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Typography.Title level={2} style={{ color: "#3052E1" }}>
              Upgrade Your Plan to Unlock Full Reporting Features
            </Typography.Title>
            <ul style={{ fontSize: "18px", lineHeight: "1.8" }}>
              <li>Gain access to detailed financial reports for better decision-making.</li>
              <li>Generate custom reports to track sales, expenses, and inventory.</li>
              <li>Monitor business performance with real-time analytics and insights.</li>
            </ul>
            <Link to={'/pricing'}>
              <Button type="primary">Upgrade Your Plan</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default GSTR1Access