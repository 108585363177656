import { IconCircleCheck, IconCrown, IconLockX } from "@tabler/icons-react";
import { Button, Col, Modal, Row, Space, Typography, message } from "antd";
import { useAtom } from "jotai";
import Lottie from "lottie-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import confetti from "../assets/animations/confetti.json";
import success from "../assets/animations/success.json";
import upgradePremium from "../assets/animations/upgradePremium.json";
import { apiClient } from "../services/http.helper";
import {
  paidUser,
  showPermissionDenied,
  showTryModal,
  showUpgradeModal,
  trialAvailable,
} from "../storage";
import { errorNotification } from "./notification.helper";

const PremiumModal = () => {
  const [openPremium, setOpenPremium] = useAtom(showUpgradeModal);
  const handleCancel = () => {
    setOpenPremium(false);
  };
  const navigate = useNavigate();
  const handleNavigatePremium = () => {
    navigate("/pricing");
  };
  return (
    <Modal
      style={{ top: 50 }}
      open={openPremium}
      closable={true}
      onCancel={handleCancel}
      width={800}
      footer={null}
    >
      <Typography.Title style={{ textAlign: "center" }} level={3}>
        Upgrade To Premium!!!
      </Typography.Title>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Space
            direction="vertical"
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Lottie
              style={{ height: 300, width: 300 }}
              animationData={upgradePremium}
              loop={true}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Unlimited Bill
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Unlimited Products
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Unlimited Parties
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Create E-Invoice
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} />
                Create E-Way Bill
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Multiple Business
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Get Access To Multiple Themes
              </p>
            </Col>
          </Row>
          <Space
            style={{ justifyContent: "center", display: "flex", marginTop: 30 }}
          >
            <Button
              type="primary"
              size="large"
              className="icon-btn"
              onClick={handleNavigatePremium}
              icon={<IconCrown size={16} style={{ color: "gold" }} />}
            >
              Upgrade Now
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

const PermissionDeniedModal = () => {
  const [isVisible, setVisible] = useAtom(showPermissionDenied);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      style={{ top: 50 }}
      open={isVisible}
      closable={true}
      onCancel={handleCancel}
      width={800}
      footer={null}
    >
      <Typography.Title style={{ textAlign: "center" }} level={3}>
        Permission Denied!!!!
      </Typography.Title>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Space
            direction="vertical"
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconLockX color="red" size={50} />
            <p>You Don't have Access to perform this operation.</p>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

const TrialModal = () => {
  const [, setTrialAvailable] = useAtom(trialAvailable);
  const [, setPaidUser] = useAtom(paidUser);
  const [loader, setLoader] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [openTryPremium, setOpenTryPremium] = useAtom(showTryModal);
  const handleCancel = () => {
    setOpenTryPremium(false);
  };
  const activateTrial = async () => {
    setLoader(true);
    try {
      let trial_response = await apiClient.post("/api/web/plan/activateTrial");
      if (trial_response.status) {
        setShowConfetti(true);
        setPaidUser(true);
        setTrialAvailable(false);
        message.success("Trial Plan Activated Successfully!!!");
        const timeoutId = setTimeout(() => {
          setOpenTryPremium(false);
        }, 3000);
      } else {
        message.error(trial_response.message);
      }
    } catch (e) {
      errorNotification("", "ModalHelperTrialPlan", JSON.stringify(e));
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      style={{ top: 50 }}
      open={openTryPremium}
      closable={true}
      onCancel={handleCancel}
      width={800}
      footer={null}
    >
      <Typography.Title style={{ textAlign: "center" }} level={3}>
        Explore Billclap Premium with 7 days Free Trial!!!
      </Typography.Title>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Space
            direction="vertical"
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Lottie
              style={{ height: 300, width: 300 }}
              animationData={upgradePremium}
              loop={true}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Space style={{ justifyContent: "center", display: "flex" }}>
            <Typography.Title level={5}>Get Benefits Upto!!!</Typography.Title>
          </Space>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add 50 Bills
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add 50 Products
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add 50 Parties
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Create E-Invoice
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Create E-Way Bill
              </p>
            </Col>

            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Get Access To Multiple Themes
              </p>
            </Col>
          </Row>
          <Space
            style={{ justifyContent: "center", display: "flex", marginTop: 30 }}
          >
            <Button
              type="primary"
              size="large"
              loading={loader}
              className="icon-btn"
              icon={<IconCrown size={16} style={{ color: "gold" }} />}
              onClick={() => activateTrial()}
            >
              Start Trial Now
            </Button>
          </Space>
        </Col>
      </Row>
      {showConfetti && (
        <Lottie
          animationData={confetti}
          loop={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            pointerEvents: "none",
          }}
        />
      )}
    </Modal>
  );
};

const SuccessModal = ({ visible }) => {
  return (
    <Modal closable={false} open={visible} width={400} footer={null}>
      <Space
        style={{
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Lottie
          style={{ height: 300, width: 300 }}
          animationData={success}
          loop={true}
        />
      </Space>
    </Modal>
  );
};

const PremiumFeature = () => {
  const navigate = useNavigate();
  const handleNavigatePremium = () => {
    navigate("/pricing");
  };
  return (
    <>
      <Typography.Title
        style={{ textAlign: "center", paddingTop: 10 }}
        level={3}
      >
        You Have Discovered a Premium Feature!!!
      </Typography.Title>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Space
            direction="vertical"
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Lottie
              style={{ height: 300, width: 300 }}
              animationData={upgradePremium}
              loop={true}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row gutter={[10, 10]} style={{ marginTop: 20 }}>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Unlimited Bill
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Unlimited Products
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Unlimited Parties
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Create E-Invoice
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Create E-Way Bill
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Add Multiple Business
              </p>
            </Col>
            <Col span={12}>
              <p className="d-flex align-item-center">
                <IconCircleCheck size={16} /> Get Access To Multiple Themes
              </p>
            </Col>
          </Row>
          <Space
            style={{ justifyContent: "center", display: "flex", marginTop: 30 }}
          >
            <Button
              type="primary"
              size="large"
              onClick={handleNavigatePremium}
              className="icon-btn"
              icon={<IconCrown size={16} style={{ color: "gold" }} />}
            >
              Upgrade Now
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export {
  PermissionDeniedModal,
  PremiumFeature,
  PremiumModal,
  SuccessModal,
  TrialModal
};

