import React, { forwardRef, useImperativeHandle, useState, IconExclamationCircle } from "react";
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  Card,
  message,
} from "antd";
import { useAtom } from "jotai";
import { business_id } from "../../storage";
import { errorNotification } from "../../utils/notification.helper";
import { apiClient } from "../../services/http.helper";


const AddEditCustomField = (props, ref) => {
  const { onChange = () => { } } = props
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm(null);
  const [businessId] = useAtom(business_id);
  const [fieldData, setFieldData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const onClose = () => {
    setOpen(false);
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        openAddDrawer(count, type) {
          setTotalCount(count);
          setFieldData({});
          form.resetFields();
          form.setFieldValue('custom_field_type', type);
          setOpen(true);
        },
        openEditDrawer(record) {
          setFieldData(record);
          form.setFieldsValue(record);
          setOpen(true);
        },
        async addRecommendedField(item) {
          await saveField(item);
        }

      }
    }, []);

  const saveCustomForm = async (values) => {
    let obj = { ...values };
    obj.field_id = totalCount + 1;
    obj.field_name = values.field_name;
    obj.field_type = values.field_type;
    obj.active = true;
    obj.required = true;
    if (fieldData?.id) {
      obj.field_id = fieldData.field_id;
      obj.id = fieldData.id;
      obj.active = fieldData.active;
      obj.required = fieldData.required;
    }
    saveField(obj);
  };

  const saveField = async (item) => {
    try {
      const obj = {
        business_id: businessId,
        ...item,
      };
      const result = await apiClient.post("api/web/custom-field/store", obj);
      if (result.status) {
        onChange();
        onClose();
        message.success(result.message, 1);
      } else {
        message.warning(result.message);
      }
    } catch (error) {
      errorNotification(JSON.stringify(), "CustomField", JSON.stringify(error));
    }
  };

  return (
    <div ref={ref}>
      <Drawer
        title="Add/Edit Custom Fields"
        width={500}
        key={"drawerAdd"}
        closable={true}
        maskClosable={false}
        destroyOnClose={false}
        onClose={onClose}
        open={open}
        extra={
          <Button type="primary" htmlType="submit" form="customForm">
            Save
          </Button>
        }
        footer={[
          <Button type="primary" htmlType="submit" form="customForm">
            Save
          </Button>,
        ]}
      >
        <Card className="ant-card-form">
          <Form
            id="customForm"
            form={form}
            layout="vertical"
            onFinish={saveCustomForm}
            autoComplete="off"
          >
            <Form.Item name="id" hidden={true}>
              <Input hidden />
            </Form.Item>
            <Form.Item name="custom_field_type" hidden={true}>
              <Input hidden />
            </Form.Item>
            <Form.Item name="field_id" hidden={true}>
              <Input hidden />
            </Form.Item>
            <Form.Item
              name="field_name"
              label="Field Name"
              rules={[
                {
                  required: true,
                  message: "Please input field name!",
                },
              ]}
            >
              <Input placeholder="Field name" />
            </Form.Item>
            <Form.Item
              name="field_type"
              label="Select"
              initialValue={"text"}
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Select>
                <Select.Option key={"text"} value="text">
                  Text
                </Select.Option>
                <Select.Option key={"number"} value="number">
                  Number
                </Select.Option>
                <Select.Option key={"date"} value="date">
                  Date
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  )
}

export default forwardRef(AddEditCustomField);
