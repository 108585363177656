import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconFile,
  IconHome,
  IconMinus,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import {
  Affix,
  AutoComplete,
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  message,
  theme,
} from "antd";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { debounce } from "lodash";
import React, { Suspense, lazy, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiClient, config } from "../../services/http.helper";
import {
  business_id,
  paidUser,
  showTryModal,
  showUpgradeModal,
  trialAvailable,
} from "../../storage";
import {
  business_signature,
  chargeDeleteList,
  invoice,
  invoiceAddCharges,
  invoiceDeleteList,
  invoiceParty,
  invoiceProductList,
  invoiceSetting,
  productDummy,
} from "../../storage/invoiceStore";
import {
  invoiceParams,
  fetchDocumentType,
  useInitializeConvertInvoice,
  useInitializeCreateInvoice,
  useInitializeEditInvoice,
  useInvoiceCalculation,
} from "../../utils/InvoiceHelperFunctions";
import { SuccessModal } from "../../utils/ModalHelper";
import { errorNotification } from "../../utils/notification.helper";
import "../sales/invoice.css"

const PartyForm = lazy(() => import("../../components/party/PartyFormDrawer").then(module => ({
  default: module.PartyForm
}))
);

const AdditonalChargesTable = lazy(
  () => import("../sales/InvoiceComponents/AdditionalChargesTable")
);
const InvoiceAddProductList = lazy(
  () => import("../sales/InvoiceComponents/InvoiceAddProductList")
);
const InvoiceBankDetails = lazy(
  () => import("../sales/InvoiceComponents/InvoiceBankDetails")
);
const InvoiceProductTable = lazy(
  () => import("../sales/InvoiceComponents/InvoiceProductTable")
);

const { Text } = Typography;
const { useToken } = theme;

const editorConfiguration = {
  toolbar: [
    "bold",
    "italic",
    "bulletedList",
    "numberedList",
    "|",
    "undo",
    "redo",
  ],
};

const CreateOrder = () => {
  const invoice_type = "invoices";
  const { token } = useToken();
  const { invoice_id, action } = useParams();
  const documentType = fetchDocumentType(invoice_type);
  const navigate = useNavigate();
  const [businessId] = useAtom(business_id);
  const [product_obj] = useAtom(productDummy);
  const [invoiceData, setInvoiceData] = useAtom(invoice);
  const resetInvoiceData = useResetAtom(invoice);
  const [party, setParty] = useAtom(invoiceParty);
  const resetParty = useResetAtom(invoiceParty);
  const [deleteItems] = useAtom(invoiceDeleteList);
  const resetDeleteProductList = useResetAtom(invoiceDeleteList);
  const [deleteCharges] = useAtom(chargeDeleteList);
  const resetDeleteChargeList = useResetAtom(chargeDeleteList);
  const [signature] = useAtom(business_signature);
  const [invoiceProduct, setInvoiceProductList] = useAtom(invoiceProductList);
  const resetInvoiceProductList = useResetAtom(invoiceProductList);
  const [customerDrawerVisible, setCustomerDrawerVisible] = useState(false);
  const [bankDrawer, setBankDrawer] = useState(false);
  const [additionalCharges] = useAtom(invoiceAddCharges);
  const [invoiceSettingData] = useAtom(invoiceSetting);
  const resetAdditionalCharges = useResetAtom(invoiceAddCharges);
  const [editNumber, setEditNumber] = useState(false);
  const [isChargesOpen, setChargesOpen] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [bankForm] = Form.useForm();
  const addProductListRef = useRef(null);
  const [fullyPaid, setFullyPaid] = useState(false);
  const [isPaid] = useAtom(paidUser);
  const [trialPresent] = useAtom(trialAvailable);
  const [, setOpenPremium] = useAtom(showUpgradeModal);
  const [, setTryPremium] = useAtom(showTryModal);
  const [editPartyId, setEditPartyId] = useState(null);
  const [partyList, setPartyList] = useState([]);
  const [isAddCustomerDrawerVisible, setIsAddCustomerDrawerVisible] =
    useState(false);
  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const autoCompleteRef = useRef(null);
  const partyType =
    invoice_type === "purchase-bill" || invoice_type === "debit-note"
      ? "SUPPLIER"
      : "CUSTOMER";

  const partyFormRef = useRef(null);
  useInitializeCreateInvoice(documentType, action);
  useInitializeEditInvoice(documentType, invoice_id, action);
  useInitializeConvertInvoice(documentType, invoice_id, action);

  useInvoiceCalculation(
    invoiceProduct,
    additionalCharges,
    invoiceData,
    setInvoiceData,
    fullyPaid
  );

  const resetInvoice = () => {
    resetDeleteChargeList();
    resetAdditionalCharges();
    resetDeleteProductList();
    resetInvoiceData();
    resetInvoiceProductList();
    resetParty();
  };

  const saveInvoice = async () => {
    // if (!isPaid && action !== "edit") {
    //   if (trialPresent) {
    //     setTryPremium(true);
    //   } else {
    //     setOpenPremium(true);
    //   }
    //   return;
    // }
    if (!party.id && !autoCompleteInput) {
      message.warning("Please Select Customer!!!");
      return;
    } else if (invoiceProduct.length < 1) {
      message.warning("Please Add Products!!!");
      openAddProductList();
      return;
    }

    try {
      const item = invoiceProduct;

      for (let i in item) {
        item[i].category = "";
        item[i].unit = "";
      }
      var obj = invoiceData;
      obj.business_id = businessId;
      obj.issue_date = dayjs(invoiceData.issue_date).format("YYYY-MM-DD");
      //   if (obj.due_date !== null) {
      //     obj.due_date = dayjs(invoiceData.due_date).format("YYYY-MM-DD");
      //   }
      obj.type = documentType;
      obj.item = item;
      //   obj.deleted_items = deleteItems;
      //   if (action === "edit") {
      //     obj.invoice_id = obj.id;
      //   } else {
      //     delete obj.id;
      //     delete obj.invoice_id;
      //   }
      obj.additional_charges = additionalCharges;
      obj.delete_charges = deleteCharges;
      obj.web = true;
      obj.party = party;
      if (!party.id && autoCompleteInput) {
        obj.create_party = true;
        obj.new_party_name = autoCompleteInput;
      } else {
        obj.create_party = false;
      }

      let invoice_response = await apiClient.post(
        "/api/shop/order/store",
        obj
      );
      if (invoice_response.status) {
        setSuccessVisible(true);
        const timeoutId = setTimeout(() => {
          resetInvoice();
          setSuccessVisible(false);
          navigate("/mydukan/orders")
        }, 2000);
      } else {
        message.error(invoice_response.message);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "CreateInvoice",
        JSON.stringify(error)
      );
    }
  };

  const toggleAdditionlCharges = () => {
    setChargesOpen(!isChargesOpen);
  };

  const openAddProductList = () => {
    addProductListRef.current?.onOpen();
  };

  const handleFullyPaidChange = (value) => {
    setFullyPaid(value);
    if (value) {
      setInvoiceData((invoiceData) => ({
        ...invoiceData,
        received_amount: invoiceData.total_amount,
      }));
    } else {
      setInvoiceData((invoiceData) => ({
        ...invoiceData,
        received_amount: 0,
      }));
    }
  };

  // const openSignatureForm = () => {
  //   signatureRef.current.onOpen();
  // };

  const onIssueDateChange = (date) => {
    setInvoiceData((invoiceData) => ({
      ...invoiceData,
      issue_date: date,
    }));
  };

  const onDueDateChange = (date) => {
    setInvoiceData((invoiceData) => ({
      ...invoiceData,
      due_date: date,
    }));
  };

  //  for the drawer

  const showCustomerDrawer = () => {
    setCustomerDrawerVisible(true);
  };

  const closeCustomerDrawer = () => {
    setCustomerDrawerVisible(false);
  };

  const showBankDrawer = () => {
    bankForm.setFieldsValue(invoiceData);
    setBankDrawer(true);
  };

  const closeBankDrawer = () => {
    setBankDrawer(false);
  };

  const searchParty = async (search_text) => {
    try {
      const obj = {
        filters: {
          party_name: search_text,
          party_type: partyType,
        },
        business_id: businessId,
      };
      const result = await apiClient.post("api/web/parties", obj);
      if (result.status) {
        setPartyList(
          result.data.map((ele, index) => ({
            key: ele.id,
            value: ele.id,
            label: renderPartyLable(ele),
            data: ele,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const renderPartyLable = (party_data) => {
    return (
      <div className="d-flex justify-content-between flex-wrap">
        <div
          style={{
            wordBreak: "normal",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {party_data.party_name}
        </div>
        <div
          style={{
            flexShrink: 0,
            color: party_data.balance?.balance > 0 ? "green" : "red",
          }}
        >
          {party_data.balance?.balance}
        </div>
      </div>
    );
  };
  const renderDropdown = (menu) => {
    return (
      <>
        {/* Render the default menu */}
        {menu}
        {/* Add a custom button at the bottom */}
        <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
          <Button
            type="link"
            className="icon-btn justify-content-start"
            style={{ fontWeight: "bold" }}
            block
            icon={<IconCirclePlus size={16} style={{ fontWeight: "bold" }} />}
            onClick={openAddParty}
          >
            Add {partyType === "CUSTOMER" ? "Customer" : "Vendor"}
          </Button>
        </div>
      </>
    );
  };

  const onSelectParty = (value, options) => {
    setParty(options.data);
  };
  const clearSelectedCustomer = () => {
    setParty({});
    setAutoCompleteInput(null);
    setEditPartyId(null);
  };
  const openAddParty = () => {
    partyFormRef.current?.openAddParty(true);
  };
  const onAddNewParty = (item) => {
    setParty(item);
  };
  const onEditCustomer = () => {
    partyFormRef.current?.openEditParty(party.id);
  };
  const onAutoCompleteInputChange = (text) => {
    setAutoCompleteInput(text);
  };
  const addNewItemRow = () => {
    const newObj = { ...product_obj };
    setInvoiceProductList((prev) => [...prev, newObj]);
  };
  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={`/`}>
                <IconHome size={16} style={{ marginTop: "3px" }} />
              </Link>
            ),
          },
          {
            title: (
              <Link to={"/mydukan/orders"}>
                <div className="d-flex align-item-center">
                  <IconFile size={16} />
                  <span> List </span>
                </div>
              </Link>
            ),
          },
          {
            title: "Create",
          },
        ]}
      />
      <Card className="ant-card-layout height-90vh">
        <Row gutter={16} justify={"space-between"} style={{ marginTop: 10 }}>
          <Col xs={24} sm={24} md={12} lg={12} style={{ marginBottom: "0.5rem" }}>
            <Card
              title={`${partyType === "CUSTOMER" ? "Customer" : "Vendor"} Details`}
            >
              {!party.id ? (
                <Space
                  direction="vertical"
                  style={{ width: "100%", display: "flex" }}
                >
                  <Typography.Text>
                    Select Customer
                  </Typography.Text>
                  <AutoComplete
                    style={{
                      width: "100%",
                    }}
                    notFoundContent="No Customer found"
                    ref={autoCompleteRef}
                    value={autoCompleteInput}
                    optionLabelProp="label"
                    onSelect={onSelectParty}
                    options={partyList}
                    placeholder="Search Customer"
                    onSearch={debounce(searchParty, 500)}
                    dropdownRender={renderDropdown}
                    onChange={onAutoCompleteInputChange}
                    onFocus={() => searchParty("")}
                  ></AutoComplete>
                </Space>
              ) : (
                <Space>
                  <Typography.Text className="d-flex flex-column">
                    <div className="d-flex">
                      <Typography.Text
                        className="fw-600"
                        style={{ fontSize: 16 }}
                      >
                        {party.party_name}
                      </Typography.Text>
                      <Button
                        className="icon-btn"
                        size="small"
                        onClick={onEditCustomer}
                        style={{ marginLeft: 10, flexShrink: 0 }}
                        icon={<IconEdit size={12} />}
                      />
                      <Button
                        className="icon-btn"
                        size="small"
                        onClick={clearSelectedCustomer}
                        style={{ marginLeft: 10, flexShrink: 0 }}
                        icon={<IconX size={12} />}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <span>GSTIN : {party.gst_no}</span>
                      <span>State : {party.billing_address_state}</span>
                      <span>Pincode:{party.billing_address_pincode}</span>
                      <span>Address:{party.billing_address_desc}</span>
                    </div>
                  </Typography.Text>
                </Space>
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} style={{ marginBottom: "0.5rem" }}>
            <Card bodyStyle={{ backgroundColor: "#f9f0ff" }}>
              <Row gutter={[10, 10]}>
                <Col
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  style={{ paddingRight: "10px" }}
                >
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  style={{ paddingRight: "10px" }}
                >
                  <Text className={["label", "bold"]}>Issue Date</Text>
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                    allowClear={false}
                    value={dayjs(invoiceData.issue_date)}
                    onChange={onIssueDateChange}
                  />
                </Col>

              </Row>
            </Card>
          </Col>
        </Row>

        <Card>
          <Row>
            <Col span={24}>
              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  icon={<IconPlus size={16} />}
                  className="icon-btn"
                  onClick={() => openAddProductList()}
                >
                  Add New Item
                </Button>
              </div>
              <Suspense fallback={<Spin size="small" />}>
                <InvoiceProductTable />
              </Suspense>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, justifyContent: "space-between" }}>
            <Button
              type="primary"
              icon={<IconPlus size={16} />}
              className="icon-btn"
              onClick={() => addNewItemRow()}
            >
              Add New Row
            </Button>
          </Row>
        </Card>
        <Card>
          <Row style={{ marginTop: 20 }}>
            <Button
              block
              className="icon-btn"
              onClick={() => toggleAdditionlCharges()}
              icon={
                !isChargesOpen ? <IconPlus size={16} /> : <IconMinus size={16} />
              }
            >
              Additional Charges
            </Button>
          </Row>

          <AdditonalChargesTable
            isChargesOpen={isChargesOpen}
            onClose={() => setChargesOpen(false)}
            onOpen={() => setChargesOpen(true)}
          />
        </Card>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={12}>
            <Card bodyStyle={{ backgroundColor: "#fafafa" }}>
              <Space
                direction="horizontal"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  marginBottom: 20,
                }}
              >
                <div>
                  <Text className={["label", "bold"]}>Bank Details</Text>
                </div>
                {invoiceData.account_number && (
                  <Button
                    className="icon-btn"
                    onClick={() => showBankDrawer()}
                    style={{ marginLeft: 10 }}
                    icon={<IconEdit size={16} />}
                  >
                    Edit
                  </Button>
                )}
              </Space>
              {invoiceData.account_number ? (
                <Col>
                  <Space>
                    <Typography.Text>
                      {invoiceData.account_number !== "" && (
                        <>
                          <div>
                            Bank Account Name : {invoiceData.account_holder_name}
                          </div>
                          <div>
                            Bank Account Number : {invoiceData.account_number}
                          </div>
                          <div>Bank Name : {invoiceData.bank_name}</div>
                          <div>IFSC Code : {invoiceData.ifsc_code}</div>
                          <div>Account Type : {invoiceData.account_type}</div>
                        </>
                      )}
                      {invoiceData.upi_id !== "" && invoiceData.upi_id
                        ? `Upi ID  : ${invoiceData.upi_id}`
                        : null}
                    </Typography.Text>
                  </Space>
                </Col>
              ) : (
                <Button
                  type="dashed"
                  style={{ width: "100%", height: 100 }}
                  icon={<IconPlus size={16} />}
                  className="icon-btn"
                  onClick={() => showBankDrawer()}
                >
                  Add Bank Details
                </Button>
              )}
            </Card>
            <Card style={{ marginTop: 10, backgroundColor: "#fafafa" }}>
              <Col
                className="notes"
                span={24}
                style={{ padding: "0px 10px", marginBottom: "0.5rem" }}
              >
                <Text className={["label", "bold"]}>Notes</Text>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={invoiceData?.invoice_notes || ``}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setInvoiceData((invoiceData) => ({
                      ...invoiceData,
                      invoice_notes: data,
                    }));
                  }}
                />
                <Typography.Text
                  style={{ fontSize: "12px", fontStyle: "italic" }}
                >
                  User Shift+Enter for single line space. Please use max 150
                  characters for better alignment
                </Typography.Text>
              </Col>
              <Col
                className="terms"
                span={24}
                style={{
                  padding: "0px 10px",
                  marginBottom: "0.5rem",
                  marginTop: 20,
                }}
              >
                <Text className={["label", "bold"]}>Terms & Conditions</Text>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={invoiceData?.term_condition || ``}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setInvoiceData((invoiceData) => ({
                      ...invoiceData,
                      term_condition: data,
                    }));
                  }}
                />
                <Typography.Text
                  style={{ fontSize: "12px", fontStyle: "italic" }}
                >
                  User Shift+Enter for single line space. Please use max 200
                  characters for better alignment
                </Typography.Text>
              </Col>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Card bodyStyle={{ backgroundColor: "#fafafa" }}>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Extra Discount
                  <InputNumber
                    value={invoiceData.additional_discount}
                    defaultValue={0}
                    min={0}
                    onChange={(value) => {
                      setInvoiceData((invoiceData) => ({
                        ...invoiceData,
                        additional_discount: value,
                      }));
                    }}
                  />
                </Col>
                <Divider />
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text>Taxable Amount</Text>
                  <Text>Total Tax</Text>
                  {additionalCharges.map((item, index) => {
                    return (
                      <Text key={index}>
                        {item.chargeName} @{item.tax}% Tax
                      </Text>
                    );
                  })}
                  <Text>
                    Round Off
                    <Switch
                      style={{ marginInlineStart: 10 }}
                      checked={invoiceData.autoRoundOffChecked}
                      onChange={(value) =>
                        setInvoiceData((invoiceData) => ({
                          ...invoiceData,
                          autoRoundOffChecked: value,
                        }))
                      }
                      size="small"
                    />
                  </Text>
                  <Text style={{ fontSize: "21px", fontWeight: 600 }}>
                    Total Amount
                  </Text>
                  <Text>Total Discount</Text>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>₹{invoiceData.taxable_amount}</Text>
                  <Text>₹{invoiceData.total_tax}</Text>
                  {additionalCharges.map((item, index) => {
                    return <Text key={index}>₹{item.chargeValue}</Text>;
                  })}

                  <Text>₹{invoiceData.roundOffAmount}</Text>
                  <Text style={{ fontSize: "21px", fontWeight: 600 }}>
                    ₹{invoiceData.total_amount}
                  </Text>
                  <Text>₹{invoiceData.total_discount}</Text>
                </Col>
              </Row>
              <Divider />
              <Col hidden={invoice_type === "quotations"}>
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <Text style={{ display: "block" }}>Add Payment </Text>
                  <Text style={{ display: "block" }}>
                    Fully Paid{" "}
                    <Switch
                      style={{ marginInlineStart: 10 }}
                      checked={fullyPaid}
                      onChange={(value) => handleFullyPaidChange(value)}
                      size="small"
                    />
                  </Text>
                </Space>

                <Space.Compact block style={{ display: "flex" }} wrap="true">
                  <InputNumber
                    min={0}
                    wheel="false"
                    value={invoiceData.received_amount}
                    onChange={(value) =>
                      setInvoiceData((invoiceData) => ({
                        ...invoiceData,
                        received_amount: value,
                      }))
                    }
                    style={{ width: "100%" }}
                  ></InputNumber>
                  <Select
                    value={invoiceData.payment_mode}
                    onChange={(value) =>
                      setInvoiceData((invoiceData) => ({
                        ...invoiceData,
                        payment_mode: value,
                      }))
                    }
                    defaultValue="Cash"
                    options={[
                      {
                        label: "Online",
                        value: "Online",
                      },
                      {
                        label: "Cash",
                        value: "Cash",
                      },
                      {
                        label: "Cheque",
                        value: "Cheque",
                      },
                      {
                        label: "Card",
                        value: "Card",
                      },
                      {
                        label: "Bank Transfer",
                        value: "BankTransfer",
                      },
                    ]}
                  />
                </Space.Compact>
              </Col>
            </Card>
            <Card
              title={"Signature"}
              style={{ marginTop: 8, backgroundColor: "#fafafa" }}
            >
              <Space style={{ justifyContent: "center", display: "flex" }}>
                <Image
                  width={150}
                  src={config.url.STORAGE_URL + "signature/" + signature}
                  preview={false}
                ></Image>
              </Space>
            </Card>
          </Col>
        </Row>
        <Affix offsetBottom={0}>
          <Space
            direction="horizontal"
            style={{
              backgroundColor: token.invoiceFooterBgColor,
              padding: 20,
              borderRadius: 6,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography.Text>
              Grand Total :{" "}
              <Text style={{ fontSize: "21px", fontWeight: 600 }}>
                ₹{invoiceData.total_amount}
              </Text>
            </Typography.Text>
            <Space style={{ rowGap: 40 }}>
              <Button size="large" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button size="large" type="primary" onClick={() => saveInvoice()}>
                Save
              </Button>
            </Space>
          </Space>
        </Affix>
      </Card>
      <InvoiceAddProductList ref={addProductListRef} />

      <InvoiceBankDetails
        form={bankForm}
        open={bankDrawer}
        onClose={closeBankDrawer}
      />
      <SuccessModal visible={successVisible} />
      <PartyForm
        ref={partyFormRef}
        type={partyType}
        onRefresh={(party_data) => onAddNewParty(party_data)}

      />
    </Space>
  );
};
export default CreateOrder;
