import { IconPlus, IconTrash, IconEdit, IconExclamationCircle } from '@tabler/icons-react';
import { Button, Space, Switch, Typography, Col, Skeleton, Alert, Table, message, Modal } from 'antd';

import React, { useEffect, useState } from 'react';
import { apiClient } from '../../services/http.helper';
import { business_id } from '../../storage';
import { useAtom } from 'jotai';

const FIELD_TYPE = "SUPPLIER";
const initialVendorRecommendedField = [
  {
    field_id: 1,
    field_name: "Account Number",
    value: "account number",
    field_type: "text",
    active: "true",
    required: "true",
    custom_field_type: FIELD_TYPE,
  },
  {
    field_id: 2,
    field_name: "IFSC CODE",
    value: "ifsc code",
    field_type: "number",
    active: "true",
    required: "true",
    custom_field_type: FIELD_TYPE,
  },
  {
    field_id: 3,
    field_name: "DL NO",
    value: "dl no",
    field_type: "text",
    active: "true",
    required: "true",
    custom_field_type: FIELD_TYPE,
  },
];

export function VendorCustomFields(props) {
  const {
    onAddCustomField = () => { },
    tabRefresh = false,
    onAddRecommendedField = () => { },
    onCustomFieldUpdate = () => { },
    addCustomForm = () => { }
  } = props;
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [businessId] = useAtom(business_id);
  const { confirm } = Modal;

  const customFieldColumns = [
    {
      title: "Name",
      dataIndex: "field_name",
      key: "field_name",
      align: "left",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (text, record, index) => (
        <Switch
          size="small"
          value={record.active}
          onChange={(e) => onChangeActiveSwitch(record, e)}
        />
      ),
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
      align: "center",
      render: (text, record, index) => (
        <Switch
          size="small"
          value={record.required}
          onChange={(e) => onChangeRequiredSwitch(record, e)}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      render: (_, record, index) => (
        <span style={{ display: "flex", justifyContent: "center", gap: "6px" }}>
          <Button
            size="small"
            type="primary"
            className="icon-btn"
            icon={<IconEdit size={14} />}
            onClick={() => onCustomFieldUpdate(record)}
          ></Button>
          <Button
            size="small"
            danger
            className="icon-btn"
            icon={<IconTrash size={14} />}
            onClick={() => deleteCustomField(record)}
          ></Button>
        </span>
      ),
    },
  ];


  useEffect(() => {
    const getVendorCustomFields = async () => {
      setLoading(true);
      try {
        const obj = {
          business_id: businessId,
          custom_field_type: FIELD_TYPE,
        };
        const result = await apiClient.post("api/web/custom-field", obj);
        if (result.status) {
          setCustomFields(result.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        message.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    }
    getVendorCustomFields();
  }, [tabRefresh]);

  const deleteCustomField = async (item) => {
    confirm({
      title: "Are you sure you want to delete this field?",
      icon: (
        <IconExclamationCircle
          size={22}
          color="red"
          style={{ marginRight: "5px", marginTop: "1px" }}
        />
      ),
      okType: "danger",
      onOk() {
        return new Promise(async (resolve, reject) => {
          const obj = {
            business_id: businessId,
            field_id: item.field_id,
            custom_field_type: FIELD_TYPE,
          };
          const result = await apiClient.post(
            "api/web/custom-field/delete",
            obj
          );
          if (result.status) {
            resolve();
          }
        })
          .then((result) => {
            setCustomFields((prev) =>
              prev.filter((field) => field.id !== item.id)
            );
            addCustomForm();
          })
          .catch((error) =>
            errorNotification(
              JSON.stringify(),
              "Custom field",
              JSON.stringify(error)
            )
          );
      },
      onCancel() { },
    });
  };

  const onChangeRequiredSwitch = async (value, e) => {
    try {
      const data = { ...value, required: e };
      const obj = {
        business_id: businessId,
        ...data,
        custom_field_type: FIELD_TYPE,
      };
      const result = await apiClient.post("api/web/custom-field/store", obj);
      if (result.status) {
        const updatedFields = customFields.map((field) => {
          if (field.id === value.id) {
            return { ...field, required: e };
          }
          return field;
        });
        setCustomFields(updatedFields);
        addCustomForm();
        message.success('Field Updated Successfully', 1);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(),
        "Custom field",
        JSON.stringify(error)
      );
    }
  };

  const onChangeActiveSwitch = async (value, e) => {
    try {
      const data = { ...value, active: e };
      const obj = {
        business_id: businessId,
        ...data,
        custom_field_type: FIELD_TYPE,
      };
      const result = await apiClient.post("api/web/custom-field/store", obj);
      if (result.status) {
        const updatedFields = customFields.map((field) => {
          if (field.id === value.id) {
            return { ...field, active: e };
          }
          return field;
        });
        setCustomFields(updatedFields);
        addCustomForm(updatedFields);
        message.success('Field Updated Successfully', 1);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(),
        "Custom field",
        JSON.stringify(error)
      );
    }
  };

  const addNewField = () => {
    onAddCustomField(customFields.length, FIELD_TYPE);
  }


  const addRecommendedField = (item) => {
    item.field_id = customFields.length + 1;
    onAddRecommendedField(item)
  }

  return (
    <Space direction="vertical" className="w-100">
      <Col span={24}>
        <Alert
          message="Note: Active fields will be shown vendor form."
          type="warning"
          showIcon
        />

        <Table
          columns={customFieldColumns}
          dataSource={customFields}
          pagination={false}
          rowKey={"id"}
          scroll={{ x: "max-content" }}
        />

      </Col>
      <Button
        type="dashed"
        className="icon-btn"
        icon={<IconPlus size={14} />}
        onClick={addNewField}
      >
        New Custom Field
      </Button>
      <Col span={24}>
        <Typography style={{ marginTop: "10px" }}>
          Recommended Fields
        </Typography>
        <Space>
          {initialVendorRecommendedField
            .filter(
              (item1) =>
                !customFields.some(
                  (item2) => item1.field_name === item2.field_name
                )
            )
            .map((item, index) => (
              <Button
                key={index}
                type="dashed"
                className="icon-btn"
                icon={<IconPlus size={16} />}
                onClick={() => addRecommendedField(item)}
              >
                {item.field_name}
              </Button>
            ))}
        </Space>
      </Col>
    </Space>
  )
}
