import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
  Drawer,
  Alert,
  Tabs,
} from "antd";
import { ItemHeader } from './ItemHeader';
import { CustomerCustomFieds } from './CustomerCustomFieds';
import { VendorCustomFields } from './VendorCustomFields';
import AddEditCustomField from './AddEditCustomField';


const CustomFieldComponent = (props, ref) => {
  const { addCustomForm = () => { } } = props
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("item");
  const addEditCustomFieldRef = useRef(null);
  const [tabRefresh, setTabRefresh] = useState(false);
  useImperativeHandle(
    ref,
    () => {
      return {
        openDrawer(type) {
          console.log("IN DRAWER", type);
          setActiveTab(type);
          setOpen(true);
        }
      }
    },
    [],
  )

  const onClose = () => {
    setOpen(false);
  }
  const onCustomFieldUpdate = (record) => {
    addEditCustomFieldRef.current?.openEditDrawer(record);
  }

  const onAddCustomField = (count, type) => {
    addEditCustomFieldRef.current?.openAddDrawer(count, type);
  }
  const onAddRecommendedField = (item) => {
    addEditCustomFieldRef.current?.addRecommendedField(item);
  }

  const tabItems = [
    {
      key: "item",
      label: "Item",
      children: <ItemHeader
        onAddCustomField={onAddCustomField}
        onCustomFieldUpdate={onCustomFieldUpdate}
        tabRefresh={tabRefresh}
        addCustomForm={addCustomForm}
      />
    },
    {
      key: "CUSTOMER",
      label: "Customer",
      children: <CustomerCustomFieds
        onAddCustomField={onAddCustomField}
        onAddRecommendedField={onAddRecommendedField}
        onCustomFieldUpdate={onCustomFieldUpdate}
        tabRefresh={tabRefresh}
        addCustomForm={addCustomForm}
      />,
    },
    {
      key: "SUPPLIER",
      label: "Vendor",
      children: <VendorCustomFields
        onAddCustomField={onAddCustomField}
        onAddRecommendedField={onAddRecommendedField}
        onCustomFieldUpdate={onCustomFieldUpdate}
        tabRefresh={tabRefresh}
        addCustomForm={addCustomForm}

      />,
    },
  ];


  const tabChange = (key) => {
    setActiveTab(key);
  }

  const updateList = () => {
    addCustomForm();
    setTabRefresh(prev => !prev);
  }

  return (
    <div ref={ref}>
      <Drawer
        title="Custom Fields"
        width={700}
        closable={true}
        maskClosable={false}
        onClose={onClose}
        open={open}
        destroyOnClose={true}

      >
        <Alert
          message="Only active fields will be shown in the documents."
          type="info"
          showIcon
          key={"alertid"}
        />
        <Tabs
          key={"tabcomponent"}
          activeKey={activeTab}
          items={tabItems}
          onChange={tabChange}
          destroyInactiveTabPane={true}
        />

        <AddEditCustomField ref={addEditCustomFieldRef} onChange={updateList} />

      </Drawer>
    </div>
  );
};

export default forwardRef(CustomFieldComponent);
