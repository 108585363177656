import { createContext, useContext } from "react";

export const PlanAccessContext = createContext({
  planAccess: null

});

export const usePlanAccess = () => {
  return useContext(PlanAccessContext);
}

export const PlanAccessProvider = PlanAccessContext.Provider;