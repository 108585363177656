import React, { useImperativeHandle, useState, forwardRef, useEffect } from "react";
import {
  Modal,
  Tabs,
  Card
} from "antd";
import { useAtom } from "jotai";
import { business_id } from "../../storage";
import { apiClient } from "../../services/http.helper";
import LedgerComponent from './Components/LedgerComponent';
import TransactionComponent from './Components/TransactionComponent';
import { errorNotification } from "../../utils/notification.helper";

const PartyLedgerModal = forwardRef((props, ref) => {
  const [businessId] = useAtom(business_id);
  const [open, setOpen] = useState(false);
  const [party, setParty] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [datefilter, setDatefilter] = useState();


  useImperativeHandle(ref, () => {
    return {
      async openModal(partyId) {
        var obj = {
          id: partyId,
          business_id: businessId,
        };
        try {
          if (datefilter) {
            obj.ledger_filter = { range: datefilter }
          }
          const result = await apiClient.post("api/web/parties/ledger", obj);
          if (result.status) {
            setParty(result.data);
            setOpen(true);
          }
        } catch (error) {
          errorNotification(
            JSON.stringify(obj),
            "partyLedger",
            JSON.stringify(error)
          );
        }
      },
    };
  }, []);


  const handleTabChange = (key) => {
    setActiveTab(key);
  }

  const tabItems = [
    {
      key: "1",
      label: "Ledger",
      children: <LedgerComponent party={party} />,
    },
    {
      key: "2",
      label: "Transactions",
      children: <TransactionComponent party={party} />,
    },
  ];

  const onCloseHandle = () => {
    setOpen(false);
    setDatefilter()
  }

  return (
    <Modal
      title={party?.party_name}
      open={open}
      onOk={onCloseHandle}
      onCancel={onCloseHandle}
      width={`80%`}
      centered
      footer={null}
      destroyOnClose={true}
      styles={{
        body: {
          height: "80vh",
        },
        mask: {
          backdropFilter: "blur(3px)"
        },
      }}
    >
      <Card className="ant-card-layout" size="default">
        <Tabs
          activeKey={activeTab}
          items={tabItems}
          onChange={handleTabChange}
        />
      </Card>
    </Modal>
  );
});

export default PartyLedgerModal;
